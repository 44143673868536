/**
 現在5月以降着手のご依頼受付中です。
(お急ぎの方は知り合いのみ要相談)

送信後、自動返信メールをお送りしますので、メールの到着をご確認ください。
後ほど入力して頂いた「ご連絡先」にて連絡いたします。

権利の譲渡は基本的に行っておりません。
 */

/**
 現在新規の方のご依頼を停止しています。
 知り合いの方はメールかDMまでご連絡頂きますようお願いいたします。
 */

/**
2022/7/13
現在、8月末以降納期のご依頼受付中です。
(お急ぎの案件は知り合いの方のみ要相談)

送信後、自動返信メールをお送りしますので、メールの到着をご確認ください。
後ほど入力して頂いた「ご連絡先」にて連絡いたします。

実績公開可のお仕事のみお受けしています。
権利の譲渡は基本的に行っておりません。
 */
/**
2024/12/14

現在、個人・企業様からのご依頼を承っております

送信後、自動返信メールをお送りしますので、メールの到着をご確認ください。
後ほど入力して頂いた「ご連絡先」にて連絡いたします。

・実績公開可のお仕事のみお受けしています。
・権利の譲渡は基本的に行っておりません。
・3営業日以内にご返信いただけることをお引き受けの条件とさせていただきます。
 */
import React from 'react';
import { Controller } from "react-hook-form";
import { FormControl, Grid, Box, Typography, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import DatePickerLimit from './DatePickerLimit';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import DialogComplete from './DialogComplete';
import OrderStep from './OrderStep';
import KindTab from './KindTab';
import Layout from '../Layout';
import { lineBreakToBr } from '../../lib/string-util';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

export default function ContactPc(props) {
  const classes = useStyles();
  const {
    register, control, handleSubmit, errors,
    tab, handleTabChange,
    isFreeMoney, handleIsFreeMoney,
    placeholder,
    dialog,
    isDisabled,
    getButtonValue,
    onSubmit,
    handleClose,
    atts,
    limitMin,
    /*openLang, setOpenLang,
    handleCloseLang,*/
    LangModel,
    setting
  } = props;

  // 金額　初期状態はドロップダウン。自由入力を選択するとテキストフィールドになる。
  const money = isFreeMoney ? (
    <Grid item style={{ marginTop: 24 }}>
      <div style={{ marginTop: 8, position: 'relative' }}>
        <TextField
          id="money" label={LangModel.get('money')}
          {...register("money", atts.money.inputRef)}
          variant="standard"
          defaultValue={null}
          error={'money' in errors}
          disabled={isDisabled()}
          fullWidth
          multiline
          rows={6}
          autoComplete='off'
          inputProps={{ maxLength: atts.money.inputRef.maxLength.value }}
          InputLabelProps={{ shrink: true }}
        />
        <div style={{ ...placeholder, position: 'absolute', color: '#a9a9a9', top: '18px', lineHeight: '22px', zIndex: -1 }}>
          {lineBreakToBr(LangModel.get('exMoney'), (key, row) => <span key={key}>{row}<br /></span>)}
        </div>
      </div>
    </Grid>
  ) : (
    <Grid item style={{ maxWidth: "180px", minWidth: "180px" }}>
      <div style={{ marginTop: 24 }}>
        <Controller
          control={control}
          id="money"
          name="money"
          defaultValue={""}
          rules={atts.money.rules}
          render={({ field }) =>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel
                shrink={true}
                error={'money' in errors}
              >
                {LangModel.get('money')}
              </InputLabel>
              <Select
                {...field}
                fullWidth
                disabled={isDisabled()}
                error={'money' in errors}
                onChange={event => {
                  if (event.target.value === '自由入力') {
                    handleIsFreeMoney();
                  } else {
                    field.onChange(event);
                  }
                }}
              >
                <MenuItem value={""}>{"　"}</MenuItem>
                {
                  setting.money ? setting.money.split("\n").map((money, index) => {
                    return (
                      <MenuItem key={`money_${index}`} value={money}>{money}</MenuItem>
                    )
                  }) : null
                }
              </Select>
            </FormControl>
          }
        />
      </div>
    </Grid>
  );

  // 会社
  const company = (tab === 0) ? (
    <Grid item>
      <TextField
        id="company" label={LangModel.get('company')}
        {...register("company", atts.company.inputRef)}
        variant="standard"
        defaultValue={null}
        error={'company' in errors}
        disabled={isDisabled()}
        fullWidth
        margin="normal"
        autoComplete='off'
        inputProps={{ maxLength: 254 }}
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
  ) : null;

  // メッセージダイアログ
  const dialogComplete = (dialog.open === true) ? (
    <DialogComplete dialog={dialog} onClose={handleClose} />
  ) : null;

  const handleClick = (lang) => {
    LangModel.set(lang);
  }

  return (
    <Layout loading={!setting.isLoaded()}>
      <Fade in={setting.isLoaded()} timeout={700}>
        <Box>
          <Container maxWidth="sm" style={{ marginTop: 30 }}>
            <FormControl fullWidth>
              <Grid
                container
                direction="column"
                spacing={1}
              >
                <form>
                  <Grid item style={{ textAlign: 'right' }}>
                    <Typography
                      variant="caption"
                      style={{ color: 'rgb(155, 155, 155)', cursor: 'pointer' }}
                      onClick={() => handleClick(LangModel.getAnotherLangKey())}
                    >
                      {LangModel.getAnotherLangValue()}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: 10, marginBottom: 50 }}>
                    <OrderStep></OrderStep>
                  </Grid>
                  <Grid item style={{ marginTop: 10, marginBottom: 40, display: "inline-block" }}>
                    <Typography style={{ marginBottom: 13 }}>{setting.noticeTitle}</Typography>
                    <Typography variant="body2">
                      {lineBreakToBr(setting.notice, (key, row) => <span key={key}>{row}<br /></span>)}
                    </Typography>
                  </Grid>
                  {/* <Grid item style={{marginTop: 0, marginBottom: 50}}>
                    <KindTab tab={tab} onChange={handleTabChange} disabled={isDisabled()}></KindTab>
                  </Grid> */}
                  {/* <Grid item>
                    <Grid container>
                      <Grid item style={{ maxWidth: "230px", minWidth: "230px" }}>
                        <TextField
                          id="name" label={LangModel.get('name')}
                          {...register("name", atts.name.inputRef)}
                          variant="standard"
                          defaultValue={null}
                          error={'name' in errors}
                          disabled={isDisabled()}
                          fullWidth
                          margin="normal"
                          autoComplete='off'
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item style={{ maxWidth: "310px", minWidth: "310px", marginLeft: "20px" }}>
                        <TextField
                          id="link" label={LangModel.get('link')}
                          {...register("link", atts.link.inputRef)}
                          variant="standard"
                          defaultValue={null}
                          error={'link' in errors}
                          disabled={isDisabled()}
                          fullWidth
                          margin="normal"
                          autoComplete='off'
                          inputProps={{ maxLength: 254 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {company}
                  <Grid item>
                    <TextField
                      id="mail" label={LangModel.get('mail')}
                      {...register("mail", atts.mail.inputRef)}
                      variant="standard"
                      defaultValue={null}
                      error={'mail' in errors}
                      disabled={isDisabled()}
                      fullWidth
                      margin="normal"
                      autoComplete='off'
                      inputProps={{ maxLength: 254 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="hear" label={LangModel.get('hear')}
                      {...register("hear", atts.hear.inputRef)}
                      variant="standard"
                      defaultValue={null}
                      error={'hear' in errors}
                      disabled={isDisabled()}
                      fullWidth
                      margin="normal"
                      autoComplete='off'
                      inputProps={{ maxLength: 254 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="title" label={LangModel.get('title')}
                      {...register("title", atts.title.inputRef)}
                      variant="standard"
                      defaultValue={null}
                      error={'title' in errors}
                      disabled={isDisabled()}
                      fullWidth
                      margin="normal"
                      autoComplete='off'
                      inputProps={{ maxLength: 80 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <TextField
                      id="description" label={LangModel.get('description')}
                      {...register("description", atts.description.inputRef)}
                      variant="standard"
                      defaultValue={null}
                      error={'description' in errors}
                      disabled={isDisabled()}
                      fullWidth
                      multiline
                      rows={6}
                      autoComplete='off'
                      inputProps={{ maxLength: 2000 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: 24, maxWidth: "180px", minWidth: "180px" }}>
                    <Controller
                      control={control}
                      id="limit"
                      name="limit"
                      rules={atts.limit.rules}
                      defaultValue={null}
                      render={({ field, fieldState }) =>
                        <DatePickerLimit
                          {...field}
                          ref={null}
                          label={LangModel.get('limit')}
                          variant="standard"
                          shrink={true}
                          error={'limit' in errors}
                          disabled={isDisabled()}
                          minDate={limitMin}
                        />
                      }
                    />
                  </Grid>
                  {money}
                  <Grid item style={{ marginTop: 40 }}>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        fullWidth
                        color="inherit"
                        type="button"
                        variant="contained" disableElevation disableRipple={false}
                        disabled={isDisabled()}
                        style={{ textTransform: 'none' }}
                      >
                        {getButtonValue()}
                      </Button>
                    </div>
                  </Grid>*/}
                </form>
              </Grid>
            </FormControl>
            {dialogComplete}
            {/* <DialogLang
              open={openLang}
              onClose={handleCloseLang}
            /> */}
          </Container>
        </Box>
      </Fade>
    </Layout>
  );
}

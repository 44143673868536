import React from 'react';
import { Controller } from "react-hook-form";
import { FormControl, Typography, Box, Fade } from '@mui/material';
import TextField from '@mui/material/TextField';
import DatePickerLimit from './DatePickerLimit';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import DialogComplete from './DialogComplete';
import OrderStep from './OrderStep';
import KindTab from './KindTab';
import Layout from '../Layout';
import { lineBreakToBr } from '../../lib/string-util';

export default function ContactSp(props) {
  const {
    register, control, handleSubmit, errors,
    tab, handleTabChange,
    isFreeMoney, handleIsFreeMoney,
    placeholder,
    dialog,
    isDisabled,
    getButtonValue,
    onSubmit,
    handleClose,
    atts,
    limitMin,
    /*openLang, setOpenLang,
    handleCloseLang,*/
    LangModel,
    setting
  } = props;

  // 金額　初期状態はドロップダウン。自由入力を選択するとテキストフィールドになる。
  const money = isFreeMoney ? (
    <div style={{ position: 'relative' }}>
      <TextField
        id="money" name="money" label={LangModel.get('money')}
        {...register("money", atts.money.inputRef)}
        defaultValue={null}
        error={'money' in errors}
        disabled={isDisabled()}
        style={{ marginTop: 16, marginBottom: 8 }}
        fullWidth
        focused
        variant="filled"
        multiline
        rows={6}
        autoComplete='off'
        inputProps={{ maxLength: atts.money.inputRef.maxLength.value }}
      />
      <div style={{ ...placeholder, position: 'absolute', color: '#a9a9a9', top: '40px', lineHeight: '22px', zIndex: -1, marginLeft: '12px' }}>
        {lineBreakToBr(LangModel.get('exMoney'), (key, row) => <span key={key}>{row}<br /></span>)}
      </div>
    </div>
  ) : (
    <Box style={{ marginTop: 16, marginBottom: 8 }}>
      <Controller
        control={control}
        id="money"
        name="money"
        defaultValue={""}
        rules={atts.money.rules}
        render={({ field, fieldState }) => {/* <FormControl error={props.error} variant="filled" fullWidth focused>
            <InputLabel
              error={'money' in errors}
            >
              {LangModel.get('money')}
            </InputLabel>
            <Select
              {...field}
              native
              fullWidth
              disabled={isDisabled()}
              error={'money' in errors}
              inputProps={{
                style: {
                  borderBottomColor: 'red',
                  '&.Mui-focused': {
                    color: 'rgb(155, 155, 155)',
                  },
                }
              }}
              onChange={event => {
                if (event.target.value === '自由入力') {
                  handleIsFreeMoney();
                } else {
                  field.onChange(event);
                }
              }}
            >
              <option aria-label="None" value="" />
              {
                setting.money ? setting.money.split("\n").map((money, index) => {
                  return (
                    <option key={`money_${index}`} value={money}>{money}</option>
                  )
                }) : null
              }
            </Select>
          </FormControl> */}
        }
      />
    </Box>
  );

  // 会社
  const company = (tab === 0) ? (
    <TextField
      id="company" name="company" label={LangModel.get('company')}
      {...register("company", atts.company.inputRef)}
      defaultValue={null}
      error={'company' in errors}
      disabled={isDisabled()}
      fullWidth
      focused
      variant="filled"
      margin="normal"
      autoComplete='off'
      inputProps={{ maxLength: 254 }}
    />
  ) : null;

  // メッセージダイアログ
  const dialogComplete = (dialog.open === true) ? (
    <DialogComplete dialog={dialog} onClose={handleClose} />
  ) : null;

  const handleClick = (lang) => {
    LangModel.set(lang);
  }

  return (
    <Layout loading={!setting.isLoaded()}>
      <Fade in={setting.isLoaded()} timeout={700}>
        <Box style={{ marginTop: 15 }}>
          <Container >
            <Box style={{ textAlign: 'right' }}>
              <Typography
                variant={"caption"}
                style={{ color: 'rgb(155, 155, 155)', cursor: 'pointer' }}
                onClick={() => handleClick(LangModel.getAnotherLangKey())}
              >
                {LangModel.getAnotherLangValue()}
              </Typography>
            </Box>
            <Box item style={{ marginTop: 10, marginBottom: 50 }}>
              <OrderStep titleAlign={"center"}></OrderStep>
            </Box>
            <Box style={{ marginTop: 10, marginBottom: 10 }}>
              <Typography variant="subtitle1" style={{ marginBottom: 16, textAlign: "center" }}>{setting.noticeTitle}</Typography>
              <Typography variant="body2" style={{ marginBottom: 20 }}>
                {lineBreakToBr(setting.notice, (key, row) => <span key={key}>{row}<br /></span>)}
              </Typography>
            </Box>
            {/* <Box>
            <KindTab tab={tab} onChange={handleTabChange} disabled={isDisabled()}></KindTab>
          </Box> */}
            <FormControl fullWidth>
              <form>
                {company}
                <TextField
                  id="name" name="name" label={LangModel.get('name')}
                  {...register("name", atts.name.inputRef)}
                  defaultValue={null}
                  error={'name' in errors}
                  disabled={isDisabled()}
                  fullWidth
                  focused
                  variant="filled"
                  margin="normal"
                  autoComplete='off'
                  inputProps={{ maxLength: 50 }}
                />
                <TextField
                  id="link" name="link" label={LangModel.get('link')}
                  {...register("link", atts.link.inputRef)}
                  defaultValue={null}
                  error={'link' in errors}
                  disabled={isDisabled()}
                  fullWidth
                  focused
                  variant="filled"
                  margin="normal"
                  autoComplete='off'
                  inputProps={{ maxLength: 254 }}
                />
                <TextField
                  id="mail" name="mail" label={LangModel.get('mail')}
                  {...register("mail", atts.mail.inputRef)}
                  defaultValue={null}
                  error={'mail' in errors}
                  disabled={isDisabled()}
                  fullWidth
                  focused
                  variant="filled"
                  margin="normal"
                  autoComplete='off'
                  inputProps={{ maxLength: 254 }}
                />
                <TextField
                  id="hear" name="hear" label={LangModel.get('hear')}
                  {...register("hear", atts.hear.inputRef)}
                  defaultValue={null}
                  error={'hear' in errors}
                  disabled={isDisabled()}
                  fullWidth
                  focused
                  variant="filled"
                  margin="normal"
                  autoComplete='off'
                  inputProps={{ maxLength: 254 }}
                />
                <TextField
                  id="title" name="title" label={LangModel.get('title')}
                  {...register("title", atts.title.inputRef)}
                  defaultValue={null}
                  error={'title' in errors}
                  disabled={isDisabled()}
                  fullWidth
                  focused
                  variant="filled"
                  margin="normal"
                  autoComplete='off'
                  inputProps={{ maxLength: 80 }}
                />
                <TextField
                  id="description" name="description" label={LangModel.get('description')}
                  {...register("description", atts.description.inputRef)}
                  defaultValue={null}
                  error={'description' in errors}
                  disabled={isDisabled()}
                  style={{ marginTop: 16, marginBottom: 8 }}
                  fullWidth
                  focused
                  variant="filled"
                  multiline
                  rows={6}
                  autoComplete='off'
                  inputProps={{ maxLength: 2000 }}
                />
                <div style={{ display: "inline-flex", width: "100%", marginTop: 16, marginBottom: 8 }}>
                  <Controller
                    control={control}
                    id="limit"
                    name="limit"
                    rules={atts.limit.rules}
                    defaultValue={null}
                    render={({ field, fieldState }) =>
                      <DatePickerLimit
                        {...field}
                        ref={null}
                        label={LangModel.get('limit')}
                        error={'limit' in errors}
                        disabled={isDisabled()}
                        variant="filled"
                        fullWidth
                        focused
                        minDate={limitMin}
                      />
                    }
                  />
                </div>
                {money}
                <Button
                  onClick={handleSubmit(onSubmit)}
                  type="button"
                  variant="outlined"
                  color="primary"
                  size="large"
                  style={{ marginTop: 30, textTransform: 'none' }}
                  fullWidth
                  disableElevation
                  disabled={isDisabled()}
                >
                  {getButtonValue()}
                </Button>
              </form>
            </FormControl>
            {dialogComplete}
            {/* <DialogLang
            open={openLang}
            onClose={handleCloseLang}
          /> */}
          </Container>
        </Box>
      </Fade>
    </Layout>
  );
}
